import React, { useEffect, useState } from 'react'
import '../css/bg-big-video.css'
import rembobinageGIF from '../videos/rembobinage.gif'
import { useTranslation } from 'react-i18next';


function BG_BIG_Video(props) {


  // let videosDirectory = "/videos/"
  let videosDirectory = "/videos/background_home/"
  let videoURL = videosDirectory + props.videoName


  const { t } = useTranslation();


  const [opacity, setOpacity] = useState([1, 0.3, 0.3]);

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity(prevOpacity => {
        return [
          prevOpacity[2],
          prevOpacity[0],
          prevOpacity[1],
        ];
      });
    }, 500); // Change l'opacité toutes les 500ms

    return () => clearInterval(interval);
  }, []);

  return (
    <>

    <div className="bg-big-video">
        <video autoPlay muted loop preload="auto" playsInline webkit-playsinline="true">
          <source src={videoURL} type="video/mp4" />
        </video>
    </div>

      <div className="bg-big-video-rembobinage">
        <div className="bg-big-video-rembobinage-video">
          <img src={rembobinageGIF} alt="video loader rembobinage pour la video de presentation de Louis gGaday" />
        </div>

        <h3 className="bg-big-video-rembobinage-title">{t('rembobinage')} <span style={{ opacity: opacity[0] }}>.</span> <span style={{ opacity: opacity[1] }}>.</span> <span style={{ opacity: opacity[2] }}>.</span></h3>

      </div>

    </>
  )
}

export default BG_BIG_Video


