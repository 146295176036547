
export default function cursorCustom(cursor, smoothCursor) {

    if (!cursor) return;

    // if smoothCursor is true, body keeps default cursor

    document.addEventListener('mousemove', e => {
        cursor.style.display = 'flex';
        // document.querySelector('body').style.cursor = 'none';
        document.querySelector('body').style.cursor = smoothCursor ? 'default': 'none';
        
        cursor.style.left = e.pageX + 'px';
        cursor.style.top = e.pageY + 'px';
    });
    document.addEventListener('mouseleave', () => {
        cursor.style.display = 'none';
        document.querySelector('body').style.cursor = 'default';
    });
    document.addEventListener('mouseenter', () => {
        cursor.style.display = 'flex';
        document.querySelector('body').style.cursor = smoothCursor ? 'default': 'none';
    });
}
