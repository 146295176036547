import React, { useEffect, useState, useContext } from 'react'
import '../css/projectvideolist.css'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'
import { ProjectListContext } from '../Context/ProjectsListContext'
import axios from 'axios'
import config from '../config.json'



function ProjectsVideosList(props) {

    let projectList = props.projectList
    const [projectListToUse, setProjectListToUse] = useState(undefined)
    const [sortedListByImportance, setSortedListByImportance] = useState()
    const { openedProjectCategorie } = useContext(ProjectListContext);
    const { t } = useTranslation();



    useEffect(() => {
        // console.log('projectList', projectList)
        if(openedProjectCategorie === 'close') return

        const setOnlyVideosOfRightCategory = () => {
            axios.get(config['apiUrl'] + 'api/projects/videosID/' + openedProjectCategorie)
            .then((response) => {
                // console.log('response.data', response.data)
                let data = response.data
                let list = []
                data.forEach(element => {
                    let content_id = element['content_id']
                    list.push(content_id)
                });
                // console.log('list', list)
                const filteredList = projectList.filter(project => list.includes(project['id']))
                // console.log('filteredList', filteredList)
                setProjectListToUse(filteredList)
            })  
            .catch((error) => {
                console.log(error);
            });
        }

        setOnlyVideosOfRightCategory()

    }, [openedProjectCategorie, projectList])

    useEffect(() => {
        if (!projectListToUse) return

        function sortProjectListByImportance() {
            const importanceHigh = []
            const importanceMedium = []
            const importanceWeak = []
            const importanceNone = []

            projectListToUse.forEach(project => {
                if (project['IMPORTANCE'] === 'high') importanceHigh.push(project)
                if (project['IMPORTANCE'] === 'medium') importanceMedium.push(project)
                if (project['IMPORTANCE'] === 'weak') importanceWeak.push(project)
                if (project['IMPORTANCE'] === 'none') importanceNone.push(project)
            });

            const sortedList = importanceHigh.concat(importanceMedium, importanceWeak, importanceNone)
            setSortedListByImportance(sortedList)
        }

        function setSizefor16_9() {
            const rootStyles = getComputedStyle(document.documentElement);
            const columns_number = parseInt(rootStyles.getPropertyValue('--grid-columns-nb-video').trim(), 10) || 3;


            const windowWidth = window.innerWidth * 0.95;
            const gap = 20;
            const videoItemWidth = (windowWidth - gap * (columns_number - 1)) / columns_number;

            document.documentElement.style.setProperty('--video-list-item-height', `${videoItemWidth}px`);

            const videoListItemHeight = (videoItemWidth * 9) / 16;
            document.documentElement.style.setProperty('--video-list-item-height', `${videoListItemHeight}px`);

            const videoListContainer = document.querySelector('.videos-list-container');
            if (videoListContainer) {
                videoListContainer.style.gridTemplateColumns = `repeat(${columns_number}, ${videoItemWidth}px)`;

                videoListContainer.style.gridTemplateRows = `repeat(30, ${videoListItemHeight}px)`;

            }
            else {
                setTimeout(() => {
                    setSizefor16_9()
                }, 100);
            }
        }


        sortProjectListByImportance()
        setSizefor16_9()

        window.addEventListener('resize', setSizefor16_9)
        return () => {
            window.removeEventListener('resize', setSizefor16_9)
        }

    }, [projectListToUse])

    // set rows number
    useEffect(() => {
        setTimeout(() => {
            const rootStyles = getComputedStyle(document.documentElement);
            const columns_number = parseInt(rootStyles.getPropertyValue('--grid-columns-nb-video').trim(), 10) || 3;


            const windowWidth = window.innerWidth * 0.95;
            const gap = 20;
            const videoItemWidth = (windowWidth - gap * (columns_number - 1)) / columns_number;

            document.documentElement.style.setProperty('--video-list-item-height', `${videoItemWidth}px`);

            const videoListItemHeight = (videoItemWidth * 9) / 16;
            document.documentElement.style.setProperty('--video-list-item-height', `${videoListItemHeight}px`);

            const videoListContainer = document.querySelector('.videos-list-container');
            if (videoListContainer) {
                videoListContainer.style.gridTemplateColumns = `repeat(${columns_number}, ${videoItemWidth}px)`;

                videoListContainer.style.gridTemplateRows = `repeat(30, ${videoListItemHeight}px)`;
                let lastRow = 0;
                let biggestBottom = 0;
                const videoListItems = document.querySelectorAll('.video-list-item');
                videoListItems.forEach(videoItem => {
                    const bottom = videoItem.getBoundingClientRect().bottom;
                    if (bottom > biggestBottom) {
                        biggestBottom = bottom
                    }
                });
                lastRow = Math.floor((biggestBottom / videoListItemHeight));
                console.log(lastRow)

                videoListContainer.style.gridTemplateRows = `repeat(${lastRow}, ${videoListItemHeight}px)`;
            }
        }, 300);

    }, [])


    return (
        <>
            <div className='videos-list-container'>
                {sortedListByImportance && sortedListByImportance.map((project, index) => {

                    const key = `${project['internal_external_video']}-${project['link_or_name_video']}-${index}`;
                    const videoURL = project['link_or_name_video'];

                    if (project['internal_external_video'] === 'internal') {
                        const videoLink = `/videos/${videoURL}`;

                        return (
                            <div key={key} className={`video-list-item ${project['SIZE']}-video`}>
                                <video playsInline controls>
                                    <source src={videoLink} type="video/mp4" />
                                </video>
                            </div>
                        );
                    } else if (project['internal_external_video'] === 'external') {


                        return (
                            <div key={key} className={`video-list-item ${project['SIZE']}-video`}>
                                <ReactPlayer
                                    url={videoURL}
                                    playing={true}
                                    controls={true}
                                    light={true}
                                    width='100%'
                                    height='100%'
                                    playsinline
                                />
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}

                {sortedListByImportance && sortedListByImportance.length === 0 && (
                    <div className='no-content'>
                        <p>{t('no-content')}</p>
                    </div>
                )}
            </div>
        </>
    );
}

export default ProjectsVideosList